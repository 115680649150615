<template>
    <div class="app-container">
        <div class="filter-container" style="display: flex;flex-wrap: wrap;">
          <el-input class="filter-item" v-model="listQuery.shop_name" placeholder="请输入店铺名称" style="width: 220px;" clearable/>

          <el-select v-model="listQuery.balance" placeholder="余额正负数" class="filter-item" style="width: 140px;" clearable>
            <el-option label="正数" value="positive" />
            <el-option label="负数" value="negative" />
          </el-select>

          <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>

          <download-excel :fields="json_fields" :fetch="handleExport" type="xls" name="店铺钱包.xls" worksheet="店铺钱包">
            <el-button type="success" :loading="exportLoading">导出</el-button>
          </download-excel>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="店铺id" width="80" align="center">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="店铺名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.shop_name }}
                </template>
            </el-table-column>

            <el-table-column label="封面" min-width="110" align="center">
                <template slot-scope="scope">
                    <img :src="scope.row.thumb" class="user-avatar">
                </template>
            </el-table-column>

            <el-table-column label="余额" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.wallet ? scope.row.wallet.balance : 0 }}
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="handleWalletLog(scope.row)">收支明细</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    import downloadExcel from "vue-json-excel";

    export default {
        components: {
            downloadExcel
        },
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    shop_name: '',
                    balance: '',
                },
                json_fields: {
                  店铺id: "id",
                  店铺名称: "shop_name",
                  余额: {
                    field: "wallet.balance",
                    callback: (value) => {
                      if (value) return value;
                      else return 0;
                    }
                  },
                },
                exportLoading: false,
            };
        },
        created() {
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/wallet/shopWallet",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            // 收支明细
            handleWalletLog(row) {
                this.$router.push('/wallet/shopWalletLog?shop_id=' + row.id)
            },
            handleExport() {
              let listQuery = JSON.parse(JSON.stringify(this.listQuery));
              listQuery.page = 1;
              listQuery.limit = this.total;
              this.exportLoading = true;
              return request({
                url: "/api/backend/wallet/shopWallet",
                method: "get",
                params: listQuery,
                timeout: 6000000,
              }).then(response => {
                if (response.data.data.length <= 0) {
                  this.$message({
                    type: "warning",
                    message: "暂无记录"
                  });
                  return;
                }
                return response.data.data;
              }).catch((err) => {
                console.log(err);
              }).finally(() => {
                this.exportLoading = false;
              });
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
